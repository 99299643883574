import {
  Box,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  List,
  ListItem,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import GoogleMapReact from 'google-map-react';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  FIND_CLASS_CLASSES_FOUND,
  FIND_CLASS_NO_CLASSES_FOUND,
  FIND_CLASS_PLACE_SELECTED,
  FIND_CLASS_SEARCH_STARTED,
} from '../../constants/events';
import { useTypedSelector } from '../../hooks/store';
import { useGetClassesQuery } from '../../store/api';
import { resultListItemStyle, resultListStyle } from '../../styles/global';
import { Place, PlaceEventData } from '../../types/places';
import analyticsEvent from '../../utils/logEvent';
import { getPlaceLat, getPlaceLng } from '../../utils/places';
import { serializePlaceForEvents } from '../../utils/serialize/place';
import ClassesList from '../classes/ClassesList';
import BecomeABossCTA from '../ctas/BecomeABossCTA';
import MapMarker from '../ui/MapMarker';

const findClassContainerStyle = {
  display: { base: 'block', lg: 'flex' },
  flexDirection: 'row-reverse',
  height: { lg: '50vh' },
  minHeight: { lg: '42rem' },
  background: 'white',
  color: 'black',
} as const;

const findClassStyle = {
  flex: 1,
  width: { base: '100%', lg: '50vw' },
  margin: 0,
} as const;

const mapContainerStyle = {
  flex: 1,
  maxWidth: { base: '100vw', lg: '50vw' },
  height: { base: '25vh', lg: '100%' },
  padding: 0,
  margin: 0,
  color: 'black',

  '> div': {
    width: { base: '100vw !important', lg: '50vw !important' },
  },
} as const;

const defaultMapProps = {
  center: {
    lat: 51.45662,
    lng: -0.13976,
  },
  zoom: 12,
};

export default function FindClass() {
  const t = useTranslations('QueenHub');
  const tGlobal = useTranslations('Global');
  const { classes, user } = useTypedSelector((state) => state);

  const [userHomeLocation, setUserHomeLocation] = useState<Place | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [selectedPlaceEventData, setSelectedPlaceEventData] = useState<PlaceEventData | null>(null);
  const [placeInputValue, setPlaceInputValue] = useState<string>('');
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);
  const [mapZoom, setMapZoom] = useState<number | null>(null);
  const [radius, setRadius] = useState<number>(10);

  // Call to get classes from db only when a place has been selected or user's home location exists
  const { data, isFetching, isLoading } = useGetClassesQuery(
    selectedPlace
      ? {
          lat: getPlaceLat(selectedPlace),
          lng: getPlaceLng(selectedPlace),
          radius: radius,
        }
      : userHomeLocation
      ? {
          lat: getPlaceLat(userHomeLocation),
          lng: getPlaceLng(userHomeLocation),
          radius: radius,
        }
      : skipToken,
  );

  // Init autocomplete places service
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      debounce: 300, // sets how often the places api is called for predictions (in ms). Increase to help quotas
    });

  const classesLoaded = data && !isPlacePredictionsLoading && !isLoading && !isFetching;

  useEffect(() => {
    // If user's home location is set, search classes for this place on initial load
    if (user.homeLocation) {
      setUserHomeLocation(user.homeLocation);
      setPlaceInputValue(user.homeLocation.formatted_address || '');
    }
  }, [user.homeLocation]);

  useEffect(() => {
    // Listen for Classes query data updated
    if (data && data instanceof Array) {
      if (data[0]) {
        // Classes endpoint returned 1+ classes
        analyticsEvent(FIND_CLASS_CLASSES_FOUND, {
          total_classes_found: data.length,
          ...selectedPlaceEventData,
        });
      } else {
        // Classes endpoint returned 0 classes
        analyticsEvent(FIND_CLASS_NO_CLASSES_FOUND, {
          ...selectedPlaceEventData,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function onInputChange(event: ChangeEvent<HTMLInputElement>): void {
    if (placeInputValue === '') {
      analyticsEvent(FIND_CLASS_SEARCH_STARTED);
    }
    if (selectedPlace) {
      setSelectedPlace(null);
    }
    getPlacePredictions({ input: event.target.value });
    setPlaceInputValue(event.target.value);
  }

  function placeSelected(place: any): void {
    setPlaceInputValue(place.description);
    placesService?.getDetails(place, (details) => {
      if (!details) return;

      const serializedPlaceEventData = serializePlaceForEvents(place, details);
      analyticsEvent(FIND_CLASS_PLACE_SELECTED, {
        ...serializedPlaceEventData,
      });
      setSelectedPlace(details);
      setSelectedPlaceEventData(serializedPlaceEventData);

      if (details.geometry?.location) {
        setMapCenter({
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        });
        setMapZoom(12);
      }
    });
    getPlacePredictions({ input: '' });
  }

  {
    /* Google maps api script must be loaded in parent page component before this component renders - see queen-hub.tsx for an example.
      Including apiKey arg in packages init functions would also trigger the script loading, but with two google packages used there's a duplicate script loaded.
      TODO: improve duplicate scripts loading due to two google packages being used, and requirement to load script on parent page */
  }

  return (
    <Box sx={findClassContainerStyle}>
      <Container sx={mapContainerStyle}>
        <GoogleMapReact
          defaultCenter={defaultMapProps.center}
          defaultZoom={defaultMapProps.zoom}
          center={mapCenter || defaultMapProps.center}
          zoom={mapZoom || defaultMapProps.zoom}
        >
          {classes.map((bossClass, index) => (
            <MapMarker
              key={`map_marker_${index}`}
              lat={parseFloat(bossClass.address.latitude)}
              lng={parseFloat(bossClass.address.longitude)}
            />
          ))}
        </GoogleMapReact>
      </Container>

      <Container size="md" sx={findClassStyle}>
        <Heading>{t('findClass.heading')}</Heading>
        <Text>{t('findClass.description')}</Text>

        {/* Place search input - changes trigger auto predictions to load */}
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            {isLoading || isFetching ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faSearch} />}
          </InputLeftElement>
          <Input
            type="text"
            fontSize={{ base: 'sm', md: 'md' }}
            placeholder={t('findClass.inputPlaceholder')}
            value={placeInputValue}
            onChange={(event) => onInputChange(event)}
          />
          <InputRightAddon paddingRight={-1}>
            <Select variant="unstyled" onChange={(event) => setRadius(Number(event.target.value))}>
              <option value={10}>{`+ 10 ${tGlobal('metrics.miles')}`}</option>
              <option value={15}>{`+ 15 ${tGlobal('metrics.miles')}`}</option>
              <option value={20}>{`+ 20 ${tGlobal('metrics.miles')}`}</option>
              <option value={25}>{`+ 25 ${tGlobal('metrics.miles')}`}</option>
              <option value={30}>{`+ 30 ${tGlobal('metrics.miles')}`}</option>
            </Select>
          </InputRightAddon>
        </InputGroup>

        {/* List of loaded places from auto prediction on users input */}
        {!isPlacePredictionsLoading && placePredictions.length > 0 && (
          <List sx={{ ...resultListStyle, background: 'gray.100' }}>
            {placePredictions.map((place: any, index) => (
              <ListItem
                sx={resultListItemStyle}
                key={`place_${index}`}
                onClick={() => placeSelected(place)}
              >
                {place.description}
              </ListItem>
            ))}
          </List>
        )}
        {classes[0] && classesLoaded && (
          <ClassesList classes={classes} setMapCenter={setMapCenter} setMapZoom={setMapZoom} />
        )}
        {!classes[0] && classesLoaded && (
          <>
            <Text mt={4} mb={12}>
              {t('findClass.noClasses')}
            </Text>
            <BecomeABossCTA />
          </>
        )}
      </Container>
    </Box>
  );
}
